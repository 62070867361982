import { batchUpdateQuickNotes, getQuickNotes } from '../services/quickNotes';

const BASE = 'QUICK_NOTES';

export const actions = {
  loadingQuickNotes: `${BASE}_LOADING_QUICK_NOTES`,
  loadedQuickNotes: `${BASE}_LOADED_QUICK_NOTES`,
};

export const setQuickNotes = (payments) => async (dispatch) => {
  dispatch({
    type: actions.loadedQuickNotes,
    payload: payments,
  });
};

export const queryQuickNotes = (callback) => async (dispatch, getState) => {
  let response;

  const {
    quickNotes: { quickNotes },
  } = getState();
  if (quickNotes.length) {
    typeof callback === 'function' && callback(quickNotes);
    return;
  }

  dispatch({ type: actions.loadingQuickNotes });

  try {
    response = await getQuickNotes();
  } catch (e) {
    response = { success: false };
  }

  const notes = response.success ? response.data || [] : [];
  notes.sort((a, b) => a.sequence - b.sequence);
  dispatch({
    type: actions.loadedQuickNotes,
    payload: notes,
  });

  typeof callback === 'function' && callback(notes);
};

export const doUpdateQuickNotes = (payload) => async (dispatch) => {
  let response;

  try {
    response = await batchUpdateQuickNotes(payload);
  } catch (e) {
    response = { success: false };
  }

  if (response.success) {
    dispatch(setQuickNotes(payload));
  }
};
