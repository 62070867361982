import { DICTIONARY_TYPES } from 'src/consts/enum';
import { API_PREFIX } from '../consts';
import { DELETE, GET, POST, PUT } from '../utils/request';

export function getQuickNotes() {
  return GET(`${API_PREFIX}/v1/restaurant/dictionaries`, { type: DICTIONARY_TYPES.QUICK_NOTES });
}

export function createQuickNotes(payload) {
  return POST(`${API_PREFIX}/v1/restaurant/dictionary`, payload);
}

export function batchUpdateQuickNotes(payload) {
  return PUT(`${API_PREFIX}/v2/restaurant/dictionaries`, {
    dictionary_type: DICTIONARY_TYPES.QUICK_NOTES,
    restaurant_dictionaries: payload,
  });
}

export function deleteQuickNotes(id) {
  return DELETE(`${API_PREFIX}/v1/restaurant/dictionary/${id}`);
}
